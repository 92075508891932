export default {
  props: ["show", "extra_money"],
  emits: ["update:show", "update:extra_money"],
  computed: {
    showPop() {
      return this.show;
    }
  },
  data() {
    return {
      extraRewardList: [6.66, 18.88, 66.66, 88.88, 168],
      checkedIndex: [6.66, 18.88, 66.66, 88.88, 168].includes(this.extra_money) ? [6.66, 18.88, 66.66, 88.88, 168].indexOf(this.extra_money) : -1,
      value: ''
    };
  },
  methods: {
    check(index) {
      this.value = '';
      this.checkedIndex = this.checkedIndex == index ? -1 : index;
    },
    checkValue() {
      this.checkedIndex = -1;
    },
    submit() {
      let money = this.checkedIndex >= 0 ? this.extraRewardList[this.checkedIndex] : this.value;
      let reword_item = JSON.parse(sessionStorage.getItem('reword_item'));
      reword_item.extra_money = money;
      sessionStorage.setItem('reword_item', JSON.stringify(reword_item));
      this.$emit('update:extra_money', money);
      this.closePopup();
    },
    closePopup() {
      this.$emit('update:show', false);
    }
  }
};