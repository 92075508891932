import { getCountryCode } from '@/api/index';
export default {
  props: ["show", "country_code", "country_name"],
  emits: ["show", "update:country_code", "update:country_name"],
  computed: {
    showPup() {
      return this.show;
    }
  },
  data() {
    return {
      countryList: [],
      themeVars: {
        pickerConfirmActionColor: '#00C4A1'
      },
      customFieldName: {
        text: 'country_name',
        value: 'country_code',
        children: []
      }
    };
  },
  methods: {
    open() {
      getCountryCode().then(res => {
        this.countryList = res.data;
        console.log(this.countryList);
      });
    },
    confirm({
      selectedOptions
    }) {
      this.$emit('update:country_code', selectedOptions[0].country_code);
      this.$emit('update:country_name', selectedOptions[0].country_name);
      this.$emit('update:show', false);
    },
    close() {
      this.$emit('update:show', false);
    }
  }
};