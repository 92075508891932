export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    actionText: {
      // 按钮文案
      type: Array,
      default: () => ['取消', '确认']
    },
    highIndex: {
      // 高亮按钮
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      // 提示信息
      type: String,
      default: ''
    }
  },
  computed: {
    ifShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      }
    }
  },
  methods: {
    btnAction(index) {
      if (this.highIndex == index) {
        this.$emit('confirm');
      } else {
        this.ifShow = false;
      }
    }
  }
};