import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1d3382bc"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "pay pay-ali"
};
const _hoisted_3 = ["src"];
import { reactive, watch } from 'vue';
import { getPayMethodList } from '@/api/index';
import { mdToast } from '../../utils/tools';
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    // 支付类型 1、众筹项目id 2、电商spuid 3、订单id
    type: {
      type: Number,
      default: 1
    },
    // 统一数据id，根据类型判断，有多个逗号分隔
    id: {
      type: String,
      default: ''
    }
  },
  emits: ['setPayType'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const state = reactive({
      methodsList: []
    });
    const getPayList = async () => {
      let option = {
        type: props.type,
        id: props.id
      };
      const {
        status,
        data,
        message
      } = await getPayMethodList(option);
      if (status == 0) {
        state.methodsList = data;
        initPayType(data);
      } else {
        mdToast(message);
      }
    };
    // 初始化setPayType
    const initPayType = list => {
      const payMethod = list.find(obj => obj.is_default === 1) || list[0];
      emits('setPayType', payMethod.pay_type);
      console.log('初始化setPayType：' + payMethod.pay_type);
    };
    watch(() => props.id, () => {
      if (props.id) {
        getPayList();
      }
    }, {
      immediate: true
    });
    watch(() => props.list, () => {
      if (props.list && props.list.length > 0) {
        state.methodsList = props.list;
        initPayType(props.list);
      }
    }, {
      immediate: true,
      deep: true
    });
    const checkPay = item => {
      state.methodsList.forEach(e => {
        e.is_default = 0;
      });
      item.is_default = 1;
      emits('setPayType', item.pay_type);
    };
    return (_ctx, _cache) => {
      return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).methodsList, (item, idx) => {
        return _openBlock(), _createElementBlock("div", {
          key: idx,
          class: "create-order-item",
          onClick: $event => checkPay(item)
        }, [_createElementVNode("span", _hoisted_2, [_createElementVNode("img", {
          class: "pay-icon",
          src: item.pay_icon
        }, null, 8, _hoisted_3), _createTextVNode(" " + _toDisplayString(item.pay_name), 1)]), _createElementVNode("i", {
          class: _normalizeClass(['check-icon', {
            'active': item.is_default || _unref(state).methodsList.length == 1
          }])
        }, null, 2)], 8, _hoisted_1);
      }), 128);
    };
  }
};