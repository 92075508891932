import { env } from "md-base-tools/env";
import paypay from '@/utils/paypay.js';
import { mapState, mapActions } from "vuex";
import { getAddressList, getRewardsPostage, mdorderCoupon } from '@/api/index';
import CompExtra from '@@/pay/extraContent.vue';
import CompcountryCode from '@@/pay/countryCode.vue';
import PaymethodList from '@@/pay/paymethodList.vue';
import MdConfirm from "@/components/common/MdConfirm.vue";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    CompExtra,
    CompcountryCode,
    PaymethodList,
    MdConfirm
  },
  computed: {
    ...mapState({
      agreement: state => state.agreement.agreement,
      userId: state => state.user.userId
    }),
    riskInfo() {
      let obj = this.agreement.find(item => item.key_name == 'zc_project_pay_agreement');
      if (obj && JSON.stringify(obj) != '{}') {
        return this.formatAgreement(obj);
      } else {
        return {};
      }
    },
    amount() {
      let num = 0.00;

      // 额外打赏
      num = Number(this.extra_money);

      // 回报档价格
      if (this.reword_item.id != -3) {
        num += Number(this.reword_item.money * this.total);
      }

      // 运费
      if (this.postage_total_amount) {
        num += Number(this.postage_total_amount);
      }

      // 优惠
      if (this.coupon_total_amount) {
        num -= Number(this.coupon_total_amount);
      }
      return num.toFixed(2);
    },
    platform() {
      if (env.isWeibo()) {
        return 'weibo';
      } else if (env.isWxApp() || env.isWeixin()) {
        return 'weixin';
      } else {
        return 'all';
      }
    }
  },
  data() {
    return {
      reword_item: {},
      addressList: [],
      // 地址列表
      addressItem: sessionStorage.getItem('address') ? JSON.parse(sessionStorage.getItem('address')) : {},
      // 选中地址
      country_code: '86',
      country_name: '',
      remark: '',
      // 订单备注
      value: '',
      total: 1,
      // 回报档数量
      ifOld: false,
      // 是否是老客
      show: false,
      extra_money: 0.00,
      // 额外打赏金额
      payType: 2,
      // 支付方式 1：支付宝 2：微信
      reward_way: 2,
      // 运费方式 2:包邮/ 1: 到付/ 3: 有邮费
      postAgeInfo: {},
      // 运费信息
      postage_total_amount: 0,
      //总运费
      mdorderCouponList: [],
      // 优惠信息
      coupon_total_amount: 0,
      // 总优惠金额
      // methodsList: [],
      showCountryCode: false,
      showWarning: true,
      payPay: null,
      pay_resource: '',
      // 支付方式
      showConfirm: false,
      // 不支持配送弹窗
      confirmMsg: ''
    };
  },
  async created() {
    window.userHeader.titleText("确认订单");
    if (!sessionStorage.getItem('reword_item')) return;
    this.reword_item = JSON.parse(sessionStorage.getItem('reword_item'));

    // 如果本地已经缓存额外打赏金额以缓存为准
    if (this.reword_item.extra_money) {
      this.extra_money = this.reword_item.extra_money;
    } else {
      if (this.reword_item.id == -3) {
        this.extra_money = 6.66;
      } else {
        //检测优惠
        this.mdorderCoupon();
        this.extra_money = 0;
      }
    }
    if (this.reword_item.total) {
      this.total = this.reword_item.total;
    }
    if (this.reword_item.remark) {
      this.remark = this.reword_item.remark;
    }
    console.log('reword_item', this.reword_item);

    // 获取收货地址
    await this.getAddress();
    this.getPostage();

    // 获取协议
    this.getAgreement();

    // 初始化支付工具
    this.payPay = new paypay({
      success: this.success,
      type: 'pro'
    });
    // const { data } = await this.payPay.getPayMethodList({ type: 1, id: this.reword_item.pro_id })
    // this.methodsList = data
  },
  watch: {
    total() {
      if (this.reword_item.id != -3) {
        this.mdorderCoupon();

        // 份数同步至本地缓存
        this.reword_item.total = this.total;
        sessionStorage.setItem('reword_item', JSON.stringify(this.reword_item));
      }
    },
    remark() {
      if (this.reword_item) {
        // 备注同步至本地缓存
        this.reword_item.remark = this.remark;
        sessionStorage.setItem('reword_item', JSON.stringify(this.reword_item));
      }
    }
  },
  methods: {
    setPayType(payType) {
      console.log(payType);
      this.pay_resource = payType;
    },
    success(e) {
      console.log(e);
    },
    checkCountryCode() {
      this.showCountryCode = true;
    },
    ...mapActions({
      getAgreement: "getAgreement"
    }),
    // 切换地址
    goToAddressList() {
      // location.href = `${domain.wap}/address/address_list?redirectUrl=${encodeURIComponent(location.origin + location.pathname)}`
      this.$router.push(`/address/address_list?redirectUrl=${encodeURIComponent(location.origin + location.pathname)}`);
    },
    // 获取默认地址接口
    async getAddress() {
      const res = await getAddressList({
        user_id: this.userId
      });
      if (res.status == 0 && res.data.length) {
        this.addressList = res.data;
        if (sessionStorage.getItem('address')) {
          let item = JSON.parse(sessionStorage.getItem('address'));

          // 如果本地缓存地址和地址列表中地址ID一致，则取对应地址，否则默认取首个
          let index = this.addressList.findIndex(ele => ele.id == item.id);
          if (index > -1) {
            this.addressItem = this.addressList[index];
          } else {
            this.addressItem = this.addressList[0];
          }
        } else {
          this.addressItem = this.addressList[0];
        }
        sessionStorage.setItem('address', JSON.stringify(this.addressItem));
      }
    },
    // 获取邮费信息
    async getPostage() {
      if (this.reword_item.id == -3 || this.reword_item.reward_way == 2) return;
      const res = await getRewardsPostage({
        province_id: this.addressItem.province_id,
        city_id: this.addressItem.city_id,
        num: this.total,
        reward_id: this.reword_item.id,
        pro_id: this.reword_item.pro_id
      });
      if (res.status == 0) {
        this.postAgeInfo = res.data;
        if (this.postAgeInfo.postage_type == 3) {
          if (this.reword_item.charge_type == 2) {
            this.postage_total_amount = Number(this.postAgeInfo.mail_amount) * this.total;
          } else {
            this.postage_total_amount = this.postAgeInfo.mail_amount;
          }
        }
      } else if (res.status == 203701) {
        this.confirmMsg = res.message;
        this.showConfirm = true;
      } else {
        mdToast(res.message);
      }
    },
    // 创建订单
    createOrder() {
      // var pay_resource = ''
      // this.methodsList.forEach(e => {
      //     if (e.is_default) {
      //         pay_resource = e.pay_type
      //     }
      // })
      let obj = {
        pay_resource: this.pay_resource,
        pro_id: this.reword_item.pro_id,
        rew_id: this.reword_item.id,
        amount: this.amount,
        address_id: this.addressItem.id,
        remark: this.remark,
        total: this.total,
        extra_money: this.extra_money,
        country_code: this.country_code,
        phone: this.value,
        postage_money: this.postage_total_amount,
        coupons: JSON.stringify(this.mdorderCouponList)
      };
      this.payPay.toPay({
        params: obj
      });
    },
    async mdorderCoupon() {
      const {
        data
      } = await mdorderCoupon({
        pro_id: this.reword_item.pro_id,
        reward_id: this.reword_item.id,
        num: this.total,
        fee_amount: this.extra_money
      });
      this.mdorderCouponList = data.coupons;
      this.coupon_total_amount = data.coupon_total_amount;
    },
    // 众筹数量-减
    onReduce() {
      if (this.total == 1) {
        return;
      } else {
        this.total -= 1;
      }
    },
    // 众筹数量-加
    onAdd() {
      if (this.reword_item.limit_per_user && this.total >= this.reword_item.limit_per_user) {
        return;
      } else {
        this.total += 1;
      }
    },
    formatAgreement(agreement_item) {
      console.log('agreement_item', agreement_item);
      if (!agreement_item) {
        return;
      }
      let showContent = agreement_item.content;
      if (agreement_item.special_key && agreement_item.special_key.length) {
        agreement_item.special_key.forEach(item => {
          let replaceContent = item.content;
          if (item.url) {
            replaceContent = this.insertLinkTag(item.url, item.content);
          }
          if (item.color) {
            replaceContent = this.insertColorTag(item.color.trim(), replaceContent);
          }
          showContent = showContent.replace(item.key_name, replaceContent);
        });
      }
      return showContent;
    },
    insertLinkTag(url, content) {
      return "<a class='blue' href=\"" + url + "\">" + content + "</a>";
    },
    insertColorTag(color, content) {
      return "<font color=\"" + color + "\">" + content + "</font>";
    }
  }
};