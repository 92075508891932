/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import env from 'md-base-tools/env';
import { post, get } from '@/utils/apiBase.js'
import { mdToast } from '@/utils/tools'
import domain from "@/service/domain";

class payPay {
  //构造器
  constructor(opt) {
    this.pay_resource = opt.pay_resource || "weixin";
    this.type = opt.type || "pro";
    this.success = opt.success;
    this.apiClient = opt.apiClient;
    this.url = "";
    this.utm = "";
    // 获取utm参数
    this.getutm();
  }

  getutm() {
    const utm = {};
    const utm_arr = [
      "utm_campaign",
      "utm_source",
      "utm_medium",
      "utm_term",
      "utm_content",
    ];
    for (let key of utm_arr) {
      let value = sessionStorage.getItem(key) || "";
      if (value) {
        utm[key] = value;
      }
    }
    this.utm = JSON.stringify(utm);
  }

  // 获取支付方式-微信内只支持微信、微博内只支持微博、浏览器内请求支付方式接口
  getPayMethodList(data) {
    return new Promise((resolve, reject) => {
      if (env.isWeixin() || env.isWxApp()) {
        resolve({ data: [{ is_default: 1, pay_type: "weixin" }] });
      } else if (env.isWeibo()) {
        resolve({ data: [{ is_default: 1, pay_type: "weibo" }] });
      } else {
        get("/apis/mdorder/pay_method_list", data)
          .then((res) => {
            if (res.status === 0) {
              resolve(res);
            } else {
              mdToast(res.message);
              reject();
            }
          })
          .catch((err) => {
            reject();
          });
      }
    });
  }
  // 去支付
  toPay({ params, type, order_id }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (env.isWeixin()) {
        params.pay_resource = "weixin";
      }
      if (env.isWeibo()) {
        params.pay_resource = "weibo";
      }
      if (type) {
        this.type = type;
      }
      if (order_id) {
        try {
          const { data } = await this.resetPay({
            order_id,
            pay_resource: params.pay_resource,
          });
          if (data) {
            if (env.isWxApp()) {
              wx.miniProgram.navigateTo({
                url: "/pages/payPages/pay_h5/pay_h5?pay_id=" + data.pay_id,
              });
            } else {
              try {
                const { data: dataSend, message } = await this.sendPay({
                  pay_resource: params.pay_resource,
                  pay_id: data.pay_id,
                });
                this.start(dataSend, message);
              } catch (e) {
                reject(e);
              }
            }
          }
        } catch (e) {
          reject(e);
        }
      } else {
        try {
          const { data } = await this.createOrder(params);
          if (data) {
            if (env.isWxApp()) {
              wx.miniProgram.navigateTo({
                url: "/pages/payPages/pay_h5/pay_h5?pay_id=" + data.pay_id,
              });
            } else {
              try {
                const { data: dataSend, message } = await this.sendPay({
                  pay_resource: params.pay_resource,
                  pay_id: data.pay_id,
                });

                console.log("支付单创建成功---", dataSend);
                this.start(dataSend, message);
              } catch (e) {
                reject(e);
              }
            }
          }
        } catch (e) {
          reject(e);
        }
      }
    });
  }
  // >>>>>创建订单
  createOrder(data) {
    return new Promise((resolve, reject) => {
      const obj = JSON.parse(JSON.stringify(data));
      if (this.type === "pro") {
        this.url = "/apis/mdorder/create_order";
      } else if (this.type === "mall") {
        this.url = "/mall/order";
      }
      obj.utm = this.utm;

      post(this.url, obj)
        .then((res) => {
          if (res.status === 0) {
            resolve(res);
          } else {
            mdToast(res.message);
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // >>>>>重置订单-已有订单支付执行此函数
  async resetPay({ pay_resource, order_id }) {
    return new Promise((resolve, reject) => {
      post("/mall/order/resetpay", { pay_resource, order_id })
        .then((res) => {
          if (res.status === 0) {
            resolve(res);
          } else {
            mdToast(res.message);
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  // >>>>>发起支付
  sendPay({ pay_resource, pay_id }) {
    const fail_next = sessionStorage.getItem("FailNext") || "href";

    return new Promise((resolve, reject) => {
      post("/apis/mdorder/sendpay", {
        pay_resource,
        pay_id,
        return_url: `${domain.wap}/order/payment/success?pay_id=${pay_id}&fail_next=${fail_next}`,
      })
        .then((res) => {
          if (res.status === 0) {
            sessionStorage.setItem("PayId", pay_id);
            resolve(res);
          } else {
            mdToast(res.message);
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  start(dataSend, message) {
    // dataSend.pay_type: weixin微信 alipay支付宝 weibo微博 weixin_mina小程序 alipay_yibao易宝 nopay无需支付 third_url三方URL
    if (dataSend) {
      if (dataSend.pay_type == "weixin" && env.isWeixin()) {
        //微信
        const json = {
          appId: dataSend.data.appid || dataSend.data.appId,
          timeStamp: dataSend.data.timestamp || dataSend.data.timeStamp,
          nonceStr: dataSend.data.noncestr || dataSend.data.nonceStr,
          package: dataSend.data.package || dataSend.data.packAge,
          signType: dataSend.data.signtype || dataSend.data.signType,
          paySign: dataSend.data.paysign || dataSend.data.paySign,
        };
        this.callWeixinPay(json);
      } else if (
        dataSend.pay_type == "alipay" ||
        dataSend.dataType == "FORMDATA"
      ) {
        //支付宝-FORMDATA
        let div = document.createElement("div");
        div.innerHTML = dataSend.data;
        document.body.append(div);
        setTimeout(() => {
          document.forms[0].submit();
        });
      } else if (dataSend.pay_type == "weixin_mina") {
        //小程序
        const get_scheme_data = dataSend.weixin_mina_path_pages.split("?");
        this.getScheme({
          jump_wxa: JSON.stringify({
            path: get_scheme_data[0],
            query: get_scheme_data[1],
          }),
        }).then((e) => {
          setTimeout(() => {
            window.open(e.data, "_self");
            // window.location.href = e.data;
          });
        });
      } else if (
        dataSend.pay_type == "weibo" ||
        dataSend.pay_type == "alipay_yibao" ||
        dataSend.pay_type == "third_url" ||
        dataSend.dataType == "URL"
      ) {
        //三方URL
        window.location.href = dataSend.data;
      }
    } else {
      mdToast(message);
    }
  }
  getScheme(option) {
    return new Promise((resolve, reject) => {
      get("/apis/weixin_api/get_scheme", option)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getPayInfo({ pay_id }) {
    return new Promise((resolve, reject) => {
      get("/apis/mdorder/payinfo", { pay_id })
        .then((res) => {
          if (res.status == 0) {
            //-1失效 0未支付 2成功
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getCookie(name) {
    var arr,
      reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
    else return null;
  }
  callWeixinPay(json) {
    const loadStatusData = setInterval(() => {
      // eslint-disable-next-line no-undef
      if (WeixinJSBridge !== undefined) {
        clearInterval(loadStatusData);
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke("getBrandWCPayRequest", json, (res) => {
          // eslint-disable-next-line no-undef
          WeixinJSBridge.log(res.err_msg);
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            // eslint-disable-next-line no-undef
          } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
            mdToast("取消支付");
          } else {
            mdToast(res.err_msg);
          }
        });
      }
    }, 300);
  }
  //界面toast提示
  /*使用方法 Toast('这是一个弹框',2000)*/
  Toast(msg, duration) {
    duration = isNaN(duration) ? 3000 : duration;
    var m = document.createElement("div");
    m.innerHTML = msg;
    m.style.cssText =
      "font-family:siyuan;max-width:60%;min-width: 150px;padding:0 14px;height: 40px;color: rgb(255, 255, 255);line-height: 40px;text-align: center;border-radius: 4px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 999999;background: rgba(0, 0, 0,.7);font-size: 16px;";
    document.body.appendChild(m);
    setTimeout(function () {
      var d = 0.5;
      m.style.webkitTransition =
        "-webkit-transform " + d + "s ease-in, opacity " + d + "s ease-in";
      m.style.opacity = "0";
      setTimeout(function () {
        document.body.removeChild(m);
      }, d * 1000);
    }, duration);
  }
}
export default payPay;
