import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-35fcfcd8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "md-confirm-box"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  key: 1,
  class: "message"
};
const _hoisted_4 = {
  class: "btns"
};
const _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
    show: $options.ifShow,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => $options.ifShow = $event)
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [$props.title && $props.title.length > 0 ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(["tit", $props.message && $props.message.length > 0 ? 'has_message' : ''])
    }, _toDisplayString($props.title), 3)) : _createCommentVNode("", true), $props.message && $props.message.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.message), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.actionText, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['btn', {
          'has_message': $props.message && $props.message.length > 0,
          'active': $props.highIndex == index || $props.actionText.length == 1
        }]),
        key: index,
        onClick: $event => $options.btnAction(index)
      }, _toDisplayString(item), 11, _hoisted_5);
    }), 128))])])]),
    _: 1
  }, 8, ["show"])]);
}