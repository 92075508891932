import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_picker = _resolveComponent("van-picker");
  const _component_van_popup = _resolveComponent("van-popup");
  const _component_van_config_provider = _resolveComponent("van-config-provider");
  return _openBlock(), _createBlock(_component_van_config_provider, {
    "theme-vars": $data.themeVars
  }, {
    default: _withCtx(() => [_createVNode(_component_van_popup, {
      show: $options.showPup,
      "onUpdate:show": _cache[0] || (_cache[0] = $event => $options.showPup = $event),
      round: "",
      position: "bottom",
      onOpen: $options.open
    }, {
      default: _withCtx(() => [_createVNode(_component_van_picker, {
        title: "地区",
        columns: $data.countryList,
        "columns-field-names": $data.customFieldName,
        onConfirm: $options.confirm,
        onCancel: $options.close
      }, {
        option: _withCtx(option => [_createTextVNode(" +" + _toDisplayString(option.country_code) + " " + _toDisplayString(option.country_name), 1)]),
        _: 1
      }, 8, ["columns", "columns-field-names", "onConfirm", "onCancel"])]),
      _: 1
    }, 8, ["show", "onOpen"])]),
    _: 1
  }, 8, ["theme-vars"]);
}